/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Container for the photo gallery */
  .photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px; /* Space between the photos */
    padding: 20px;
    background-color: white;
  }
  
  /* Each photo item */
  .photo-item {
    width: calc(16.66% - 20px); /* 6 images per row, with margins accounted */
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .photo-item:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  /* Caption that shows up when hovering */
  .photo-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: none; 
    color: #fff;
    font-size: 14px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .photo-item:hover .photo-caption {
    opacity: 1;
  }
  
  /* Modal overlay when a photo is clicked */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Close button for the modal */
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .photo-item {
      width: calc(33.33% - 30px); /* 3 images per row on smaller screens */
      height: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .photo-item {
      width: calc(50% - 30px); /* 2 images per row on mobile */
      height: 120px;
    }
  }
  

  .image-caption a {
    text-decoration: none;
    color: #1a73e8;
    font-size: 14px;
    text-align: center;
    display: block;
    margin-top: 30px;
  }
  
  .image-caption a:hover {
    color: #1a73e8;
    text-decoration: underline;
  }
  