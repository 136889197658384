/* Center the Writings content */
.writings-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: monospace;
}

/* Style for the main title */
.writings-content h2 {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 20px; /* Add space below the title */
}

/* Style for topic headers */
.topic {
    font-size: 1.8em;
    color: #555;
    margin: 50px 0px -50px -100px;/* Space between sections */
    border-bottom: 2px solid #eee; /* Add a subtle separator */
    padding-bottom: 0px;
}

/* Style for each writing item */
.writing-item {
    margin-bottom: 0px; /* Space between topics */
}

/* Style for each date and article link */
.date {
    font-size: 1em;
    color: #555;
    margin: 0px 0px 0px 0px; /* Space between dates */
    font-style: normal!important;
}

.date a {
    color: #0066cc;
    text-decoration: none;
    font-weight: bold;
}

/* Hover effect for article links */
.date a:hover {
    text-decoration: underline;
}

/* Style for the "More" link */
.more-link {
    font-size: 1.5em; 
    margin-left: -100px;
    color: #0066cc;
    font-weight: bold; 
    text-decoration: none;
    display: inline-block;
}

/* Hover effect for "More" link */
.more-link:hover {
    text-decoration: underline;
}
