/* Infinite Time Tunnel Effect */
.infinite-time-tunnel {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    perspective: 1200px;
  }
  
  .tunnel-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tunnel-ring {
    position: absolute;
    border-radius: 50%;
    border: 5px solid rgba(0, 255, 204, 0.8);
    animation: infiniteTunnel 5s linear infinite;
    transform-origin: center;
  }
  
  .tunnel-ring:nth-child(1) {
    width: 50px;
    height: 50px;
    border-color: rgba(255, 105, 180, 0.8);
    animation-delay: -1s;
  }
  
  .tunnel-ring:nth-child(2) {
    width: 100px;
    height: 100px;
    border-color: rgba(255, 204, 0, 0.8);
    animation-delay: -2s;
  }
  
  .tunnel-ring:nth-child(3) {
    width: 150px;
    height: 150px;
    border-color: #32CD32;
    animation-delay: -3s;
  }
  
  .tunnel-ring:nth-child(4) {
    width: 200px;
    height: 200px;
    border-color: #FF69B4;
    animation-delay: -4s;
  }
  
  .tunnel-ring:nth-child(5) {
    width: 250px;
    height: 250px;
    border-color: #0066FF;
    animation-delay: -5s;
  }
  
  .tunnel-ring:nth-child(6) {
    width: 300px;
    height: 300px;
    border-color: #FF3333;
    animation-delay: -6s;
  }
  
  @keyframes infiniteTunnel {
    0% {
      transform: scale(0.1) translateZ(-1000px);
      opacity: 0.8;
    }
    100% {
      transform: scale(3) translateZ(0);
      opacity: 0;
    }
  }
  
  /* Dark mode page structure */
  .dark-mode-page {
    background-color: #000;
    color: #00ffcc;
    min-height: 100vh;
    padding: 20px;
    text-align: center;
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  
  .dark-mode-title {
    font-size: 3rem;
    color: #FFCC00;
    text-shadow: 2px 2px 10px #ffcc00;
    margin-top: -20px;
  }
  
