/* ThingsContent.css */

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif; 
}

/* Title */
h2 {
    text-align: center;
    color: #333; 
    font-size: 30px;
    margin-bottom: 10px; 
    font-family: monospace;
}

/* Date Styling */
.date {
    font-style: italic;
    color: #777;
    margin-top: 90px; 
    font-size: 20px; 
    padding-right: 30px;
    margin-bottom: 0px;
    text-align: left; /* Align the date to the left */
    display: block; /* Ensure it behaves as a block element */
}

/* Subheading Styling */
h3 {
    margin-top: 70px; /* Adjusted margin to reduce the gap */
    color: #555;
    text-align: left; /* Ensure subheading is aligned to the left */
}

/* List Styling */


li {
    margin-bottom: 10px;
    line-height: 1.6;
    color: #444;
    font-family: monospace; 
    font-size: 15px;
}

.sc1 {
    color: #555; /* Set the color of the text */
    font-size: 25px; /* Adjust the font size */
    font-weight: bold; /* Make the text bold */
    text-align: left; /* Align the text to the left */
    margin-top: 70px; /* Space above the subtitle */
    margin-bottom: 15px; /* Space below the subtitle */
    letter-spacing: 1px; /* Add some letter spacing */
    font-family: monospace; /* Monospace font style */
}

.sc2 {
    color: #555; /* Set the color of the text */
    font-size: 25px; /* Adjust the font size */
    font-weight: bold; /* Make the text bold */
    text-align: left; /* Align the text to the left */
    margin-top: 70px; /* Space above the subtitle */
    margin-bottom: 15px; /* Space below the subtitle */
    letter-spacing: 1px; /* Add some letter spacing */
    font-family: monospace; /* Monospace font style */
}

