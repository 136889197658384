

html.dark-mode, body.dark-mode {
  overflow: hidden; /* Prevent scrolling in dark mode */
}

html:not(.dark-mode), body:not(.dark-mode) {
  overflow: auto; /* Allow scrolling in light mode */
}

.intro {
  font-family: Monospace;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.intro strong {
  font-weight: bold;
}

.intro a {
  color: #007bff;
  text-decoration: none;
}

.intro a:hover {
  text-decoration: underline;
}

.intro b {
  font-weight: bold;
}

.intro blockquote {
  font-style: italic;
  margin-left: 20px;
  padding-left: 10px;
  border-left: 2px solid #333;
}

.footage {
  font-size: 1.2em;
  text-align: center;
  margin: 20px 0;
  margin-top: 50px;
}

.footage a {
  text-decoration: none;
  font-family: Monospace;
  color: #1a73e8;
  margin: 0 15px;
  transition: color 0.3s;
}

.footage a:hover {
  color: #ff4500;
}

.container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 50px;
  padding-top: 0;
}

.top-left-image {
  width: 300px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
}

.content {
  flex-grow: 1;
}

.code {
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
}

.dark-mode-switch {
  position: fixed;
  top: 17px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}

.dark-mode-switch button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer; 
  padding-top: 2px;
}

.dark-mode-switch button:hover {
  opacity: 0.7;
}

.button-container {
  margin: 0 10px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  font-family: Monospace;
  margin-right: 40px;
  position: fixed;
  top: 20px;
  right: 10px; 
}

.button-container button {
  margin: 0px 10px;
  background-color: transparent;
  padding: 10px 20px;
  cursor: pointer;
  color: grey;
  border: none;
  border-radius: 5px;
  font-family: Monospace;
}

.button-container button:hover {
  color: #1a73e8;
  font-weight: bold;
}

#morse-code:hover {
  color: #1a73e8;
}
/* Ensure dark mode occupies the entire viewport and prevents scroll */
.dark-mode-page {
  background-color: #1e1e1e; /* Dark background color */
  color: #ffffff; /* White text color */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  overflow: hidden; /* Prevent scrolling in dark mode */
  position: fixed; /* Fix position to occupy the full screen */
  top: 0;
  left: 0;
}

/* Content container in dark mode takes full space without any margin or padding */
.dark-mode .content-container {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Ensure there's no scroll in dark mode */
  display: flex;
  align-items: center;
  justify-content: center;
}


.dark-mode-page h1 {
  font-size: 3em;
  margin-bottom: 0px;
  font-weight: bold;
}

.dark-mode-page p {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.dark-mode-content p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #ddd;
}

.dark-mode-content:hover {
  background-color: #444;
  color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Responsive Styling */
@media (max-width: 600px) {
  .dark-mode-page {
    padding: 10px;
  }

  .dark-mode-page h1 {
    font-size: 2.5em;
  }

  .dark-mode-page p {
    font-size: 1.2em;
  }

  .dark-mode-content {
    padding: 15px;
  }
}

.top-left-corner .name {
  font-weight: bold;
  color: grey;
  margin-right: 250px;
  margin-left: 10px;
  font-size: 18px;
}

.top-left-corner:hover {
  font-style: italic;
  font-weight: bold;
  color: black;
}

.top-left-corner {
  position: fixed; 
  top: 18px; /* Change this value to move it down vertically */
  left: 10px; /* Change this value to move it horizontally */
  padding-top: 5px;
  padding: 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  z-index: 1000; /* Ensure it stays on top of other elements */
  transform: translate(0, 0); /* You can use this to fine-tune positioning */
}

.content-container {
  padding-top: 70px;
  padding-left: 25px;
  padding-right: 20px; 
  padding-bottom: 25px;
}

.image-caption {
  text-align: center;
  font-size: 14px;
  color: #888;
  margin-top: 170px; 
  font-family: monospace
}

.toggle-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-top: 20px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #0c47a1;
} 

.element-class {
  width: 300px;
  margin: 0 auto;
}